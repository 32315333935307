//load base url according to the environment
export const BASE_URL = process.env.REACT_APP_API_URL
export const BASE_WS = process.env.REACT_APP_WEBSCKET_URL

//redux
export const AUTHENTICATED = 'AUTHENTICATED'
export const NOT_AUTHENTICATED = 'NOT_AUTHENTICATED'

//status tickets
export const NEW_TICKETS = 4
export const PENDING_TICKETS = 3
export const CLOSED_TICKETS = 2
export const OPEN_TICKETS = 1

//Notifciations
export const COUNT_NOTIFICATIONS = "COUNT_NOTIFICATIONS"
export const CHANGE_SELECTED_NOTIFICATION = 'CHANGE_SELECTED_NOTIFICATION'

//status drawer
export const OPEN_DRAWER = 'OPEN_DRAWER'
export const NOT_OPEN_DRAWER = 'NOT_OPEN_DRAWER'

//status search bar
export const WRITE_SEARCH = 'WRITE_SEARCH'

//routes
export const NAME_ROUTES = [
    {
        plural: 'abiertos',
        singular: 'abierto'
    },
    {
        plural: 'nuevos-tickets',
        singular: 'nuevo-ticket'
    },
    {
        plural: 'recibidos',
        singular: 'recibido'
    },
    {
        plural: 'enviados',
        singular: 'enviado'
    },
    {
        plural: 'cerrados',
        singular: 'cerrado'
    },
    {
        plural: 'necesitan-datos',
        singular: 'necesita-datos'
    },
]