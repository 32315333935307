import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
// import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import { connect } from 'react-redux';
import socketSubscribe from '../../HOC/ESocket';
import { compose } from 'redux'
import SnackNotification from '../ESnackBar/ESnackBarReply'
import { getTickets } from '../../../api/tickets';
import EWebNotifications from '../EWebNotifications/EWebNotifications';


const styles = theme => ({
  root: {
    width: '50%'
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
});

class EHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      mobileMoreAnchorEl: null,
      ticket: {},
      ticketsNotifications: [],
      opnSnackNot: false,
      anchorElNotifications: null,
    };
    // this.initialiseSocketTickets();
    this.getLastTicket = this.getLastTicket.bind(this)
    this.handleCloseMenuNotifications = this.handleCloseMenuNotifications.bind(this)
    this.renderMenuNotifications = this.renderMenuNotifications.bind(this)
  }

  // React methods
  componentDidMount() {
    this.getLastTicket()
  }

  static getDerivedStateFromProps(props, state) {
    if (Object.keys(props.ticket).length > 0) {
      if (props.ticket.id !== state.ticket.id) {
        state.ticketsNotifications.shift()
        state.ticketsNotifications.push(props.ticket)
        props.onCountNotifications(props.countNotifications + 1)
        return {
          ticket: props.ticket,
          opnSnackNot: true,
        }
      }
    }
    return null;
  }

  //Class methods
  async getLastTicket() {
    try {
      const filterLast = { status: 3, last: 5 }
      const ticketArray = await getTickets(filterLast)
      if (ticketArray) {
        this.setState({ ticketsNotifications: ticketArray.results.reverse() })
      }
    } catch (error) {
      console.log(error)

    }
  }

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleCloseSession = () => {
    this.props.signOff()
    this.props.children.history.push('/login')
  }

  handleDrawerOpen = () => {
    if (this.props.isOpenDrawer) {
      this.props.onCloseDrawer()
    } else {
      this.props.onOpenDrawer()
    }
  }

  handleSearchChange = (event) => {
    this.props.onWriteSearch(event.target.value)
  }


  handlerCloseSnackStatus = () => {
    if (this.props.countNotifications > 0) {
      this.props.onCountNotifications(0)
      this.setState({
        opnSnackNot: false,
      })
    }
  }

  handleGotoAllNewTickets = () => {
    this.props.children.history.push('/nuevos-tickets')
    this.handleCloseMenuNotifications()
  }

  handlerClickNotificationsTicket = (ticket) => {
    const { id } = ticket
    this.props.onChangeSelectedNotification(ticket)
    this.props.children.history.replace({
      pathname: `/nuevo-ticket/${id}`,
      state: {
        detail: ticket,
        mayUserReply: true
      }
    })
  }

  handleOpenMenuNotifications = (event) => {
    this.setState({
      anchorElNotifications: event.currentTarget,
    });
    this.handlerCloseSnackStatus()
  };

  handleCloseMenuNotifications = () => {
    this.setState({
      anchorElNotifications: null,
    });
    this.handleMobileMenuClose();
  };

  renderSnackBarAction = () => (
    <Grid>
      <Button onClick={this.handlerCloseSnackStatus} color="secondary" size="small">X</Button>
    </Grid>
  )

  renderMenu = () => {
    return (
      <Menu
        anchorEl={this.state.anchorEl}
        open={Boolean(this.state.anchorEl)}
        onClose={this.handleMenuClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={this.handleMenuClose}>Mi cuenta</MenuItem>
        <MenuItem onClick={this.handleCloseSession}>Cerrar sesión</MenuItem>
      </Menu>
    )
  }

  renderMenuNotifications = () => {
    return <Menu
      anchorEl={this.state.anchorElNotifications}
      open={Boolean(this.state.anchorElNotifications)}
      onClose={this.handleCloseMenuNotifications}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      {
        this.state.ticketsNotifications ?
          this.state.ticketsNotifications.map((value, index) => {
            return (
              <MenuItem key={'menu-item-notification' + index} onClick={() => { this.handlerClickNotificationsTicket(value) }}>
                <Typography variant='caption'><b>{value.code}</b> - </Typography>
                <br></br>
                <Typography variant='caption'>{value.subject.substring(0, 20) + (value.subject.length > 20 ? '...' : '')}</Typography>
              </MenuItem>
            )
          }) : null
      }
      <MenuItem style={{ textAlign: 'center', color: 'red' }} onClick={this.handleMenuClose}>
        <Typography variant="caption" onClick={this.handleGotoAllNewTickets} style={{ color: '#0097EF' }}><b>Ver todos</b></Typography>
      </MenuItem>
    </Menu>
  }

  render() {
    const { anchorEl, anchorElNotifications } = this.state;
    const { classes } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    const isMenuNotificationsOpen = Boolean(anchorElNotifications);
    return (
      <div className={classes.root}>
        <AppBar position="fixed">
          <Toolbar className={classes.menuBar}>
            <Hidden mdUp>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
            <Typography className={classes.title} variant="h6" color="inherit" noWrap>
              Gestion tickets
            </Typography>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                onChange={this.handleSearchChange}
              />
            </div>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <IconButton
                aria-owns={isMenuNotificationsOpen ? 'material-appbar' : undefined}
                aria-haspopup="true"
                onClick={this.handleOpenMenuNotifications}
                color="inherit"
              >
                <Badge badgeContent={this.props.countNotifications} color="secondary" invisible={this.props.countNotifications <= 0}>
                  <MailIcon />
                </Badge>
              </IconButton>
              <IconButton
                aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                aria-haspopup="true"
                onClick={this.handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
                <MoreIcon />
              </IconButton>
            </div>
            <div>
              {this.renderMenu()}
              {this.renderMenuNotifications()}
            </div>
          </Toolbar>
        </AppBar>
        {this.state.opnSnackNot ?
          <SnackNotification
            open={this.state.opnSnackNot}
            msg="Nuevo Ticket creado!!"
            action={this.renderSnackBarAction()}
          // closeOn={(e) => console.log(e)} 
          /> : null
        }
        <EWebNotifications ticket={this.props.ticket} />
        
      </div>
    );
  }
}

EHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isOpenDrawer: state.drawerReducer.isOpenDrawer,
    textSearch: state.headerReducer.textSearch,
    countNotifications: state.notificationsReducer.countNotifications,
    selectedNotification: state.notificationsReducer.selectedNotification
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signOff: () => dispatch({ type: 'NOT_AUTHENTICATED' }),
    onOpenDrawer: () => dispatch({ type: 'OPEN_DRAWER' }),
    onCloseDrawer: () => dispatch({ type: 'NOT_OPEN_DRAWER' }),
    onWriteSearch: (state) => dispatch({ textSearch: state, type: 'WRITE_SEARCH' }),
    onCountNotifications: (state) => dispatch({ countNotifications: state, type: 'COUNT_NOTIFICATIONS' }),
    onChangeSelectedNotification: (state) => dispatch({ selectedNotification: state, type: 'CHANGE_SELECTED_NOTIFICATION' }),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  socketSubscribe

)(EHeader);