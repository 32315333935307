import React, {
  Component
} from 'react';
import { BASE_WS } from '../../constants'

function socketSubscribe(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.socketRef = null;
      this.connect = this.connect.bind(this)
      this.state = {
        ticket: {},
        countTicket: 0

      }
    }

    componentDidMount() {
      this.connect()

    }

    componentWillUnmount() {
      this.disconnect();
    }

    connect() {
      const path = BASE_WS
      this.socketRef = new WebSocket(path + "/ws/tickets/")

      this.socketRef.onopen = e => {
        console.log('start to connect to websocket')
        console.log(e)
      }

      this.socketRef.onmessage  = e => {
        const message = this.processMesage(e.data)
        console.log(message)
        this.setState({ ticket: {...message['ticket']}})
      }
    }
    disconnect() {
      this.socketRef.close();
    }

    processMesage(data) {
      return JSON.parse(data)
    }

    render() {
      return(
        <WrappedComponent countTicket={this.state.countTicket} ticket={this.state.ticket} {...this.props}/>
      )
    }
    
  }
}

export default socketSubscribe;