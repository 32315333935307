import React, {
  Component
} from 'react';
import ListTickets from '../components/EListTickets/EListTickets';
import {
  getTickets
} from '../api/tickets'
import EPagiantion from '../components/Common/EPagination/EPagination';
import ETicketsLoader from '../components/Common/ELoading/ETicketsLoader';

class AbiertosTickets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      next: '',
      previous: '',
      pageCount: 0,
      count: 0,
      openeTickets: [],
      isLoading: true,
    }
    this.getOpenedTickets = this.getOpenedTickets.bind(this)
    this.handlerPageClick = this.handlerPageClick.bind(this)
  }

  componentDidMount() {
    this.getOpenedTickets()
  }

  async getOpenedTickets() {
    try {
      const params = {
        status: '1',
      }
      const {
        count,
        next,
        previous,
        results
      } = await getTickets(params)
      const pageCount = Math.trunc((count / 20) + 1)
      if (results) {
        this.setState(
          {
            pageCount,
            next,
            count,
            previous,
            openeTickets: results,
            isLoading: false
          }
        )
      }
    } catch (error) {
      console.log(error)
    }
  }

  //handlers
  async handlerPageClick(infoPage) {
    const { selected } = infoPage
    const page = selected + 1
    const params = { status: '1', page }
    const { results } = await getTickets(params)
    if (results) {
      console.log('enter here')
      this.setState({ openeTickets: results })
    }
  }
  handlerClickTicket = (ticket) => {
    const {
      history
    } = this.props
    const {
      id
    } = ticket
    history.push({
      pathname: `/abierto/${id}`,
      state: {
        detail: ticket,
        mayUserReply: true,
        mayUserClose: true,
        mayUserNeedData: true,
      }
    })
  }
  render() {
    return (
      <>
        {
          this.state.isLoading ?
            <ETicketsLoader /> :
            <ListTickets
              header={"Tickets abiertos"}
              tickets={this.state.openeTickets}
              onClickTicket={this.handlerClickTicket}
            />
        }
        {
          this.state.openeTickets.length > 0 ?
            <EPagiantion
              pageCount={this.state.pageCount}
              handlerPageClick={this.handlerPageClick}
            /> : null
        }
      </>
    )
  }
}

export default AbiertosTickets;