import React, { Component } from 'react';
import MetricsCard from '../components/Common/EMetrics/EMetrics';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ChartMetrics from '../components/Common/EChart/EChart';
import { getMetrics } from '../api/tickets';
import Divider from '@material-ui/core/Divider';
import Loading from '../components/Common/ELoading/ELoading';

const styles = theme => ({
  rootHome: {
    flexGrow: 1,
    margin: 20,
    padding: 30,
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    padding: 20
  },
  chart: {
    paddingTop: 10,
    width: '100%'
  }
})


class HomeView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      metrics: [],
      loading: false
    }
    this.getHomeMetrics = this.getHomeMetrics.bind(this)

  }

  componentDidMount() {
    this.getHomeMetrics()
  }

  async getHomeMetrics() {
    try {
      this.setState({ loading: true })
      const metrics = await getMetrics()
      if (metrics) {
        this.setState({ metrics, loading: false })
      }
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.rootHome}>
        <Typography variant='h5'>Dashboard</Typography>
        <div style={{ paddingTop: 10, paddingBottom: 10, width: '100%' }}>
          <Divider />
        </div>
        {this.state.loading ? <Loading /> :
          <div>
            <Grid container>
              <Grid item style={{width:'100%'}}>
                <Grid container spacing={8}>
                  {this.state.metrics.map((data, index) => {
                    return (<Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                      <MetricsCard
                        msg={data.msg}
                        metric={data.metric}
                        color={data.color}
                        icon={data.icon}
                      />
                    </Grid>
                    )
                  })}
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item lg={12} className={classes.chart}>
                <ChartMetrics metrics = {this.state.metrics} />
              </Grid>
            </Grid>
          </div>
        }
      </div>
    )
  }
}

export default withStyles(styles)(HomeView);