import React, { Component } from 'react';
import ListTickets from '../components/EListTickets/EListTickets';
import { getTickets } from '../api/tickets';
import EPagiantion from '../components/Common/EPagination/EPagination';
import ETicketsLoader from '../components/Common/ELoading/ETicketsLoader';

class NecesitanDatosTickets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageCount: 0,
      count: 0,
      tickets: [],
      isLoading: true
    }
    this.getTickets = this.getTickets.bind(this)
    this.handlerPageClick = this.handlerPageClick.bind(this)
  }

  componentDidMount() {
    this.getTickets()
  }

  async getTickets() {
    try {
      const filter = { status: '4' }
      const { count, results } = await getTickets(filter)
      const pageCount = Math.trunc((count / 20) + 1)
      if (results) {
        this.setState({
          tickets: results,
          pageCount,
          isLoading: false
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  //handlers
  async handlerPageClick(infoPage) {
    const { selected } = infoPage
    const page = selected + 1
    const params = { status: '4', page }
    const { results } = await getTickets(params)
    if (results) {
      this.setState({ newTickets: results })
    }
  }

  handlerClickTicket = (ticket) => {
    const { history } = this.props
    const { id } = ticket
    history.push({
      pathname: `/necesita-datos/${id}`,
      state: {
        detail: ticket,
        mayUserReply: true,
        mayUserOpen: true
      }
    })
  }



  render() {
    return (
      <>
        {
          this.state.isLoading ?
            <ETicketsLoader /> :
            <ListTickets
              header={"Tickets que necesitan más datos"}
              tickets={this.state.tickets}
              onClickTicket={this.handlerClickTicket}
            />
        }
        {
          this.state.tickets.length > 0 ?
            <EPagiantion
              pageCount={this.state.pageCount}
              handlerPageClick={this.handlerPageClick}
            /> : null
        }
      </>
    )

  }
}

export default NecesitanDatosTickets;