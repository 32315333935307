import React from 'react';
// import {withStyles} from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const EAttachment = ({ attachments, onDeleteChip }) => (
  attachments.map((item, i) => (
    <Chip
      key={i}
      label={item.name || item.name_attachment}
      style={{margin: 7}}
      component="a"
      onDelete={() => onDeleteChip(item)}
      href={item.file || item.attachment || null} 
      clickable
      target="_blank"
    />
    )
  )
)

export default EAttachment
