import { COUNT_NOTIFICATIONS, CHANGE_SELECTED_NOTIFICATION } from '../constants'


const initialState = {
    countNotifications: 0,
    selectedNotification: {}
}

const notificationsReducer = (state = initialState, action) => {
        console.log(action.type)
    switch (action.type) {
        case COUNT_NOTIFICATIONS:
            return {
                ...state,
                countNotifications: action.countNotifications
            }
        case CHANGE_SELECTED_NOTIFICATION:
            return {
                ...state,
                selectedNotification: action.selectedNotification
            }
        default:
        console.log(state)
            return state;
    }
}
export default notificationsReducer;