import React from 'react';
import Moment from 'moment';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import InboxIcon from '@material-ui/icons/Inbox';
import Divider from '@material-ui/core/Divider';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';


const styles = theme => ({
  root: {
    margin: 20,
    padding: 30,
    backgroundColor: theme.palette.background.paper,
  },
  seenNormal: {

  },
  noSeenBorder: {
    borderLeft: '5px solid #0097EF',
    fontWeight: 'bold'
  }
})

class ListTickets extends React.Component {
  render() {
    const { header, classes, tickets, onClickTicket } = this.props
    return (
      <div className={classes.root}>
        <Hidden mdUp>
          <Grid item xs={12}>
            <Grid container direction="row" alignItems="center" justify="center">
              <span style={{ width: '100vh' }}></span>
            </Grid>
          </Grid>
        </Hidden>
        <Typography variant='h5'>{header}</Typography>
        <div style={{ paddingTop: 10, paddingBottom: 10, width: '100%' }}>
          <Divider />
        </div>
        <List component="nav">
          {tickets.length > 0 ? tickets.map((value) => (
            value.subject.toLowerCase().search(this.props.textSearch.toLowerCase()) !== -1 ||
              value.code.toLowerCase().search(this.props.textSearch.toLowerCase()) !== -1
              ?
              <ListItem
                className={!value.seen ? classes.noSeenBorder : classes.seenNormal}
                key={value.id} role={undefined}
                dense button
                onClick={() => onClickTicket(value)}
              >
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary={"codigo " + value.code + " " + (value.subject.length < 50 ? value.subject : (value.subject.substring(0, 73) + '...'))} secondary={value.email_user} />
                <ListItemSecondaryAction>
                  <Typography variant='caption'>
                    {Moment.utc(value.created).format('D MMMM YYYY - hh:mm:ss a')}
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
              : null
          ))
            : <Typography style={{ textAlign: 'center' }} variant='h6'>No hay tickets para esta seccion</Typography>}
        </List>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    textSearch: state.headerReducer.textSearch
  }
}

export default connect(mapStateToProps, null)(withStyles(styles)(ListTickets));