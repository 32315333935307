import React from 'react';
import { BrowserRouter as  Router, Route, Switch, Redirect } from 'react-router-dom'
import LoginLayout from '../layouts/LoginLayout';
import HomeLayout from '../layouts/HomeLayout';
import InboxLayout from '../layouts/InboxLayout';
import LoginView from '../views/LoginView';
import HomeView from '../views/HomeView';
import NuevoTickets from '../views/NuevoTicketsView';
import AbiertosTickets from '../views/AbiertosTicketsView';
import RecibidosTickets from '../views/RecibidosTicketsView';
import CerradosView from '../views/CerradosTicketsView';
import NecesitanDatosTicketsView from '../views/NecesitanDatosTicketsView';
import DetailTicket from '../views/DetailTicket';
import InboxView from '../views/InboxView';
import EnviadosView from '../views/EnviadosView';
import { connect } from "react-redux";
import NotFound from '../components/Common/ENotFound/ENotFound';
/**
 * Routers wrapper
 */

 //see https://reacttraining.com/react-router/web/api/Route/render-func
const LoginRoute = ({ component: Component, ...rest}) => {
    return (
        <Route {...rest} render={matchProps => 
            rest.auth ? (
                <Redirect
                    to={{
                        pathname: '/home',
                        state: {from: matchProps.location}
                    }}
                />
            ) : (
                <LoginLayout>
                    <Component {...matchProps}/>
                </LoginLayout>
                )
            }
        />
    )
}

const HomeRoute = ({ component: Component, ...rest}) => {
    return (
        <Route {...rest} render={ matchProps =>
            rest.auth ? (
                <HomeLayout>
                    <Component {...matchProps}/>
                </HomeLayout>    
            ) : (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: {from: matchProps.location}
                    }}
                />
            )}
        />
    )
}


const InboxRoute = ({ component: Component, ...rest}) => {
    return (
        <Route {...rest} render={matchProps => (
            <InboxLayout>
                <Component {...matchProps}/>
            </InboxLayout>
        )}/>
    )
}

export const TicketsRoute = (props) => {
    const { isAuth } = props
    return(
        <Router>
            <Switch>
                <InboxRoute auth={isAuth} exact={true} path="/home/" component={InboxView}/>
            </Switch>
        </Router>
    )
}


const MainRoute = (props) => {
    const { isAuth } = props
    return(
        <Router>
            <Switch>
                <Route exact path="/" render={() => (<Redirect to="/home" />)} />
                <LoginRoute auth={isAuth} path="/login" component={LoginView}/>
                <HomeRoute auth={isAuth} path="/home" component={HomeView}/>
                <HomeRoute auth={isAuth} path="/nuevos-tickets" component={NuevoTickets}/>
                <HomeRoute exact auth={isAuth} path="/nuevo-ticket/:id" component={DetailTicket}/>
                <HomeRoute auth={isAuth} path="/abiertos" component={AbiertosTickets}/>
                <HomeRoute auth={isAuth} path="/abierto/:id" component={DetailTicket}/>
                <HomeRoute auth={isAuth} path="/recibidos" component={RecibidosTickets}/>
                <HomeRoute auth={isAuth} path="/recibido/:id" component={DetailTicket}/>
                <HomeRoute auth={isAuth} exact={true} path="/enviados/" component={EnviadosView}/>
                <HomeRoute auth={isAuth} exact path="/enviado/:id" component={DetailTicket}/>
                <HomeRoute auth={isAuth} exact={true} path="/cerrados/" component={CerradosView}/>
                <HomeRoute auth={isAuth} exact path="/cerrado/:id" component={DetailTicket}/>
                <HomeRoute auth={isAuth} exact={true} path="/necesitan-datos/" component={NecesitanDatosTicketsView}/>
                <HomeRoute auth={isAuth} exact path="/necesita-datos/:id" component={DetailTicket}/>
                <Route component={NotFound}/>
            </Switch>
        </Router>
    )
}

const mapStateToProps = (state) => {
    return {
      isAuth: state.userReducer.isAuth
    }
}


export default connect(mapStateToProps, undefined)(MainRoute);
