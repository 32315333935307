import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline'
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import CommentIcon from "@material-ui/icons/Comment";
import { withRouter } from 'react-router'
import { connect } from "react-redux";

const drawerWidth = 240;

const styles = theme => ({
  rootDrawer: {
    display: 'flex',
  },
  zIndexDesktop: {
    display: 'flex',
    zIndex: 1,
  },
  zIndexMovil: {
    display: 'flex',
    zIndex: 1000,
  },
  icon: {
    margin: theme.spacing.unit * 2,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 1,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
    zIndex: 20000
  },
  toolbar: theme.mixins.toolbar,
});

class EDrawer extends React.Component {

  constructor(props) {
    super(props)
    this.state = {}
  }

  renderDrawer = (isHandledByOpenDrawer) => {
    const { classes, history, isOpenDrawer } = this.props;
    return (
      <Drawer
        className={classes.drawer}
        variant="persistent"
        open={isHandledByOpenDrawer ? isOpenDrawer : true}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.toolbar} />
        <List>
          {['Home', 'Nuevos Tickets', 'Abiertos', 'Necesitan datos', 'Cerrados'].map((text, index) => {
            const url = '/' + text.replace(' ', '-').toLowerCase()
            return (
              <ListItem button key={text} onClick={() => history.push(url)}>
                <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            )
          })
          }

        </List>
        <Divider />
        <List>
          <ListItem button key={5}>
            <ListItemIcon><CommentIcon /></ListItemIcon>
            <ListItemText primary='Respuesta rápida' />
          </ListItem>
        </List>
      </Drawer>
    )
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.rootDrawer}>
        <CssBaseline />
        <Hidden mdUp>
          {this.renderDrawer(true)}
        </Hidden>
        <Hidden smDown>
          {this.renderDrawer(false)}
        </Hidden>
      </div>
    );
  }
}

EDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isOpenDrawer: state.drawerReducer.isOpenDrawer
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onOpenDrawer: () => dispatch({ type: 'OPEN_DRAWER' }),
    onCloseDrawer: () => dispatch({ type: 'NOT_OPEN_DRAWER' }),
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EDrawer)));