import { combineReducers } from 'redux';
import userReducer from './userReducer'
import drawerReducer from './drawerReducer'
import headerReducer from './headerReducer'
import notificationsReducer from './notificationsReducer'

const rootReducers = combineReducers({
    userReducer,
    drawerReducer,
    headerReducer,
    notificationsReducer
})

export default rootReducers;