import React, { Component } from 'react';
import Notification from '../../../../node_modules/react-web-notification/lib/components/Notification';
import { withStyles } from '@material-ui/core/styles';
import ReactAudioPlayer from 'react-audio-player';
import sound from '../../../assets/beep.ogg';

const styles = theme => ({})

class EWebNotifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ticket: props.ticket,
            title: '',
            ignore: true,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (typeof prevProps.ticket.code !== 'undefined' && prevProps.ticket.code !== prevState.ticket.code) {
            this.setState({ ticket: prevProps.ticket }, this.handleSendWebNotification(prevProps.ticket))
        }
    }

    handlePermissionGranted() {
        this.setState({
            ignore: false,
        }, console.log('Permission Granted'));
    }
    handlePermissionDenied() {
        this.setState({
            ignore: true,
        }, console.log('Permission Denied'));
    }
    handleNotSupported() {
        this.setState({
            ignore: true,
        }, console.log('Web Notification not Supported'));
    }

    handleNotificationOnClick(e, tag) {
        //console.log(e, 'Notification clicked tag:' + tag);
    }

    handleNotificationOnError(e, tag) {
        //console.log(e, 'Notification error tag:' + tag);
    }

    handleNotificationOnClose(e, tag) {
        //console.log(e, 'Notification closed tag:' + tag);
    }

    handleNotificationOnShow(e, tag) {
        this.playSound();
        //console.log(e, 'Notification shown tag:' + tag);
    }

    playSound() {
        this.rap.audioEl.play()
    }

    handleSendWebNotification(ticket) {

        if (this.state.ignore) {
            return;
        }

        const now = Date.now();

        const title = 'Gestión de tickets';
        const body = 'Nuevo ticket: \n' + ticket.code + ' - \n' + ticket.subject;
        const tag = now;
        const icon = 'http://georgeosddev.github.io/react-web-notification/example/Notifications_button_24.png';

        const options = {
            tag: tag,
            body: body,
            icon: icon,
            lang: 'es',
            dir: 'ltr',
            sound: './appointed.mp3'
        }
        this.setState({
            title: title,
            options: options
        });
    }

    render() {
        return (
            <div>
                <Notification
                    ignore={this.state.ignore && this.state.title !== ''}
                    notSupported={this.handleNotSupported.bind(this)}
                    onPermissionGranted={this.handlePermissionGranted.bind(this)}
                    onPermissionDenied={this.handlePermissionDenied.bind(this)}
                    onShow={this.handleNotificationOnShow.bind(this)}
                    onClick={this.handleNotificationOnClick.bind(this)}
                    onClose={this.handleNotificationOnClose.bind(this)}
                    onError={this.handleNotificationOnError.bind(this)}
                    timeout={5000}
                    title={this.state.title}
                    options={this.state.options}
                />
                <ReactAudioPlayer
                    src={sound}
                    ref={(element) => { this.rap = element; }}
                    autoPlay={false}
                    controls
                    onError={(e) => console.log(e)}
                />
            </div>
        )
    }
}

export default withStyles(styles)(EWebNotifications);