import api from './api';

export async function getTickets(params) {
    try {      
        const { status, data } = await api.get('/api/v1/tickets/', { params })
        if (status === 200 ) return data
    } catch (error) {
        console.log(error)
    }
}

export async function getDetailTicket(id) {
    try {
        const { status, data } = await api.get(`/api/v1/tickets/${id}`)
        if ( status === 200 ) {
            return data
        }
    } catch (error) {
        console.log(error)
    }
}


export async function createTickets(payload, type='') {
    try {
      const { status, data } = await api.post('/api/v1/answer-tickets/', JSON.stringify(payload))
      if (status === 201) {
        const response = {status, data}
        return response
      }
    } catch (error) {
      console.log(error)
      const response = { status: error.response.status, data: []}
      return response
    }
}

export async function setStatusTicket(payload) {
    try {
        const { status, data } = await api.post('/api/v1/tickets/set-status-ticket/', JSON.stringify(payload))
        if ( status === 200 ) {
            return data
        }
    } catch (error) {
        console.log(error)
    }
}

export async function setSeenTicket(payload) {
    try {
        const { status, data } = await api.post('/api/v1/tickets/set-seen-ticket/', JSON.stringify(payload))
        if ( status === 200 ) {
            const response = {status, data}
            return response
        }
    } catch (error) {
        console.log(error)
    }
}


export async function getMetrics() {
    try {
        const { status, data} = await api.get('/api/v1/tickets/ticket-metrics/')
        if ( status === 200) {
            return data
        }
    } catch (error) {
        
    }
}

export async function createAttachments(multipleAttachment){
  try {
    const response = await api.post('/api/v1/attachments-tickets/', multipleAttachment, { headers: { 'Content-Type': 'multipart/form-data' }})
    return response
  } catch (error) {
    console.log(error)
    const response = { status: error.response.status, data: []}
    return response
  }

}
