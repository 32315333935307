import api from './api.js';


export async function getDetailAssignedUser(id) {
    try {
        const {status, data} = await api.get(`api/v1/users/${id}/`)
        if (status === 200) {
            return data
        }
    } catch (error) {
        console.log(error)
    }
}