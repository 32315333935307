import React, { Component } from 'react';
import ListTickets from '../components/EListTickets/EListTickets';
import { getTickets } from '../api/tickets';
import EPagiantion from '../components/Common/EPagination/EPagination';
import ETicketsLoader from '../components/Common/ELoading/ETicketsLoader';

class NuevoTickets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageCount: 0,
      count: 0,
      newTickets: [],
      isLoading: true
    }
    this.getNewTickets = this.getNewTickets.bind(this)
    this.handlerPageClick = this.handlerPageClick.bind(this)
  }

  componentDidMount() {
    this.getNewTickets()
  }

  async getNewTickets() {
    try {
      const filter = { status: '3' }
      const { results, count } = await getTickets(filter)
      const pageCount = Math.trunc((count / 20) + 1)
      if (results) {
        this.setState({
          newTickets: results,
          pageCount,
          isLoading: false
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  //handlers
  async handlerPageClick(infoPage) {
    const { selected } = infoPage
    const page = selected + 1
    const params = { status: '3', page }
    const { results } = await getTickets(params)
    if (results) {
      this.setState({ newTickets: results })
    }
  }

  handlerClickTicket = (ticket) => {
    const { history } = this.props
    const { id } = ticket
    history.push({
      pathname: `/nuevo-ticket/${id}`,
      state: {
        detail: ticket,
        mayUserReply: true,
        mayUserClose: true,
        mayUserNeedData: true,
      }
    })
  }
  render() {
    return (
      <>
        {
          this.state.isLoading ?
            <ETicketsLoader /> :
            <ListTickets
              header={"Nuevos tickets"}
              tickets={this.state.newTickets}
              onClickTicket={this.handlerClickTicket}
            />
        }
        {this.state.newTickets.length > 0 ?
          <EPagiantion
            pageCount={this.state.pageCount}
            handlerPageClick={this.handlerPageClick}
          /> : null}
      </>
    )
  }
}

export default NuevoTickets;