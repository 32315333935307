import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';


const styles = (theme) => ({
    snackBar: {
        margin: theme.spacing.unit
    }
})

const SnackBarReply = ({classes, open, duration, closeOn, msg, action}) => {
    
    return (
        <Snackbar
            message={msg}
            open={open}
            autoHideDuration={6000 || duration}
            onClose={closeOn}
            action={action}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        />
    )
}

export default withStyles(styles)(SnackBarReply);