import React, { Component } from 'react'
import InboxComponent from '../components/EInbox/EInbox'

export default class InboxView extends Component {
  render() {
    return (
      <div>
        <InboxComponent/>
      </div>
    )
  }
}
