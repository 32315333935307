import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Hidden, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ReactLoading from 'react-loading';

const styles = theme => ({
  divLoader: {
    width: '100%',
    position: 'absolute',
    left: 0,
    top: '45%'
  },
  loader: {
    margin: '0 auto',
    width: 110 + 'px !important',
    height: 20,
    fill: '#0097EF !important'
  }
})

const ETicketsLoader = ({ classes }) => {
  return (
    <div className={classes.divLoader}>
      <Grid container>
        <Hidden mdDown>
          <Grid item lg={1}>
          </Grid>
        </Hidden>
        <Grid item xs={12} md={12} lg={11}>
          <ReactLoading type="bubbles" className={classes.loader} />
          <br/>
          <Typography style={{color: '#9E9E9E', textAlign: 'center'}} variant="caption">Cargando</Typography>
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(ETicketsLoader)
