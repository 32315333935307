import React from 'react'
import ReactPaginate from 'react-paginate';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
      
    },
    i: {
      listStyleType: 'none'
    },
    a: {
      listStyleType: 'none',
      color: 'black',
      float: 'left',
      padding: '8px 16px',
      textDecoration: 'none',
      transition: 'background-color .3s',
      border: '1px solid #ddd',
      margin: '0 4px',
      fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
      cursor: 'pointer'

    },
    active: {
      color: 'white',
      float: 'left',
      textDecoration: 'none',
      backgroundColor: '#0097EF',
    }
})


const EPagiantion = ({pageCount, handlerPageClick, classes}) => {
  return (
    <div>
      <ReactPaginate
        className={classes.pagination}
        previousLabel={'◄'}
        nextLabel={'►'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        pageClassName={classes.i}
        activeLinkClassName={classes.active}
        pageLinkClassName={classes.a}
        previousClassName={classes.i}
        nextClassName={classes.i}
        previousLinkClassName={classes.a}
        nextLinkClassName={classes.a}
        onPageChange={handlerPageClick}
        containerClassName={classes.root}
        subContainerClassName={'pages pagination'}
      />
    </div>
  )
}
export default withStyles(styles)(EPagiantion);
