import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { BarChart, Bar, Tooltip, Cell, CartesianGrid, XAxis, YAxis } from 'recharts';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';

const styles = theme => ({
  root: {
    flexGrow: 1,
    margin: 20,
    padding: 30,
    backgroundColor: theme.palette.background.paper,
  },
  labels: {
    display: 'flex',
    alignItems: 'center'
  },
  filter: {
    textAlign: 'right'
  },
  squareLegend: {
    height: 10,
    width: 10,
    marginRight: 4,
    marginLeft: 12
  }
})


class ChartMetrics extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      colors: [],
      barChartMetrics: [],
    }
    this.getBarCharMetrics = this.getBarCharMetrics.bind(this)
    this.renderBarCharMetrics = this.renderBarCharMetrics.bind(this)
  }

  componentDidMount() {
    this.getBarCharMetrics()
  }

  async getBarCharMetrics() {
    try {
      const metricsAux = []
      const colors = []
      this.setState({ loading: true })

      if (this.props.metrics && this.props.metrics.length > 0) {
        this.props.metrics.forEach(value => {
          const nameAux = value.msg.split(" ").pop()
          metricsAux.push({ name: nameAux.charAt(0).toUpperCase() + nameAux.slice(1), value: value.metric, color: value.color, empty: '' })
          colors.push(value.color)
        })

        this.setState({
          barChartMetrics: metricsAux,
          colors: colors
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  renderBarCharMetrics = (width, height) => {
    return (
      <Grid container alignItems="center" justify="center">
        <BarChart width={width} height={height} data={this.state.barChartMetrics}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="15 5" />
          <XAxis dataKey={width > 350 ? "name" : 'empty'} />
          <YAxis dataKey="value" />
          <Tooltip />
          {
            this.state.colors && this.state.barChartMetrics ?
              <Bar dataKey='value'>
                {
                  this.state.barChartMetrics.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={this.state.colors[index]} />
                  ))
                }
              </Bar>
              : null
          }
        </BarChart>
      </Grid>
    )
  }

  renderLegend = () => {
    const classes = this.props.classes
    return (
      <Grid container direction="row" alignItems="center" justify="center">
        {this.state.barChartMetrics ?
          this.state.barChartMetrics.map((value, index) => (
            <Grid item key={'legend-' + index}>
              <Grid container direction="row" alignItems="center" justify="center">
                <Grid item><div className={classes.squareLegend} style={{ backgroundColor: value.color }}></div></Grid>
                <Grid item> <Typography variant="subtitle1">{value.name}</Typography></Grid>
              </Grid>
            </Grid>
          ))
          : null
        }
      </Grid>
    )
  }

  render() {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='h5'>Gráfica Tickets</Typography>
          <div style={{ paddingTop: 10, paddingBottom: 10, width: '100%' }}>
            <Divider />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Hidden mdDown>
            {this.renderBarCharMetrics(500, 300)}
          </Hidden>
          <Hidden smDown lgUp>
            {this.renderBarCharMetrics(450, 200)}
          </Hidden>
          <Hidden mdUp>
            {this.renderBarCharMetrics(400, 250)}
          </Hidden>
        </Grid>
        <Hidden mdUp>
          <Grid item xs={12}>
            {this.renderLegend()}
          </Grid>
        </Hidden>
        <Grid item xs={12}>
          {/*this.renderLegend()*/}
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(ChartMetrics);