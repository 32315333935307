import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Send from '@material-ui/icons/SendOutlined'
import Close from '@material-ui/icons/CloseOutlined';
import AllTickets from '@material-ui/icons/LocalOfferOutlined'
import NewTicket from '@material-ui/icons/ExposurePlus1Outlined'

const styles = (theme) => ({
  root: {
    flex: 1
  },
  icon: {
    textAlign: 'right'
  }
})

const getIcon = (icon) => {
  const icons = {
    close: <Close style={{ fontSize: 30, color: "#f50057" }} fontSize="large" ></Close>,
    send: <Send style={{ fontSize: 30, color: "#2196f3" }} fontSize="large" ></Send>,
    newTicket: <NewTicket style={{ fontSize: 30, color: "#00e676" }} fontSize="large" ></NewTicket>,
    all: <AllTickets style={{ fontSize: 30, color: "#ffea00" }} fontSize="large" ></AllTickets>
  }
  for (let iconKey of Object.keys(icons)) {
    if (iconKey === icon) {
      return icons[iconKey]
    }
  }
}


const MetricsCard = ({ classes, msg, metric, color, icon }) => {
  return (
    <div style={{ width: '100%' }}>
      <Card elevation={1}>
        <CardContent >
          <Grid container direction="column">
            <Grid item xs={12}>
              <Typography style={{ color: color }} component="h2" variant="h4">{metric}</Typography>
            </Grid>
            <Grid item xs={11} sm={11} md={11} lg={11}>
              <Grid container direction="row">
                <Grid item xs={9}>
                  <Typography variant="subtitle2">Tickets</Typography>
                  <Typography variant="subtitle2">{msg.split(" ").pop()}</Typography>
                </Grid>
                <Grid item xs={3}>
                  {getIcon(icon)}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}

export default withStyles(styles)(MetricsCard);