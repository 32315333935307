import React, { Component } from 'react';
import ListTickets from '../components/EListTickets/EListTickets';
import { getTickets } from '../api/tickets';

class SentTickets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sentTickets: []
        }
        this.getSentTickets = this.getSentTickets.bind(this)
    }

    componentDidMount() {
        this.getSentTickets()
    }

    async getSentTickets() {
        try {
            const filter = { status: 1}
            const sentTickets = await getTickets(filter)
            if (sentTickets) {
                this.setState({ sentTickets: sentTickets })
            }
        } catch (error) {
            console.log(error)
        }
    }

    //handlers
    handlerClickTicket = (ticket) => {
        const { history } = this.props
        const { id } = ticket
        history.push({
            pathname: `/enviado/${id}`,
            state: {
                detail: ticket,
                mayUserReply: true
            }
        })
    }



    render() {
        return (
            <ListTickets
                header={"Enviados"}
                tickets={this.state.sentTickets}
                onClickTicket={this.handlerClickTicket}
            />
        )
    }
}

export default SentTickets;