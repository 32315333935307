import { AUTHENTICATED , NOT_AUTHENTICATED} from '../constants' 


const initialState = {
    isAuth: false
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTHENTICATED:
            return {
                ...state,
                isAuth: true
            }
        case NOT_AUTHENTICATED:
            return {
                ...state,
                isAuth: false
            }
        default:
            return state;
    }
}
export default userReducer;