import React from 'react';
import Moment from 'moment';
import Header from '../components/Common/EHeader/EHeader'
import EDrawer from '../components/Common/EDrawer/Edrawer';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
    container: {
        display: 'flex',
        flexFlow: 'row wrap'
    },
    header: {
        width: '100%',
        height: '10%'
    },
    drawer: {
        display: 'flex',
        /*width: '19%',
        height: '90%',*/
    },
    content: {
        flexGrow: 1,
        /*marginTop: '5%',
        width: '100%'*/
    }

})


const homeLayout = (props) => {
    const { classes } = props;
    Moment.locale('es', {
        months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
        monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split('_'),
        weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
        weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
        weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
    }
    )
    
    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <Header 
                  children={props.children.props}
                />
            </div>
            <div className={classes.drawer}>
                <EDrawer />
            </div>
            <div className={classes.content}>
                {props.children}
            </div>
        </div>

    )
}

export default withStyles(styles)(homeLayout);