import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'
import Moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import IconReply from '@material-ui/icons/Reply';
import IconCancel from '@material-ui/icons/Cancel';
import IconAssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';
import IconAssignmentLate from '@material-ui/icons/AssignmentLate';
import classNames from 'classnames';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Hidden from '@material-ui/core/Hidden';
import Slide from '@material-ui/core/Slide';
import { setStatusTicket } from '../../../api/tickets';
import SnackBarStatus from '../ESnackBar/ESnackbar';
import Attachment from '../EAttachment/EAttachment'


const styles = theme => ({
  root: {
    width: '90%',
    margin: 20,
  },
  paper: {
    height: "100%",
    padding: 20
  },
  headerControls: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end'
  },
})

const STATUS = [
  {
    codigo: 0,
    descripcion: null,
    mensajeBoton: "",
    mensajeConfirmacion: "",
    redirectTo: '/home'
  },
  {
    codigo: 1,
    descripcion: 'Abierto',
    mensajeConfirmacion: ' Desea marcar el ticket como "Abierto"?',
    redirectTo: '/necesitan-datos'
  },
  {
    codigo: 2,
    descripcion: 'Cerrado',
    mensajeConfirmacion: ' Desea marcar el ticket como "Cerrado"?',
    redirectTo: '/recibidos'
  },
  {
    codigo: 3,
    descripcion: 'Pendiente',
    mensajeConfirmacion: ' Desea marcar el ticket como "Pendiente"?',
    redirectTo: '/recibidos'
  },
  {
    codigo: 4,
    descripcion: 'Necesita datos',
    mensajeConfirmacion: ' Desea marcar el ticket como "Necesita datos"?',
    redirectTo: '/recibidos'
  },
]

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class HeaderTicketMsg extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalOpen: false,
      redirectClose: false,
      showSnackStatus: true,
      responseStatus: null,
      newTicketStatus: 0,
      previousRoute: this.props.previousRoute
    }
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleOpenModalToOpenTicket = this.handleOpenModalToOpenTicket.bind(this)
    this.handleOpenModalToCloseTicket = this.handleOpenModalToCloseTicket.bind(this)
    this.handleOpenModalToNeedDataTicket = this.handleOpenModalToNeedDataTicket.bind(this)
    this.handleChangeStatusTicket = this.handleChangeStatusTicket.bind(this)
  }

  handleCloseModal = () => { this.setState({ isModalOpen: false }) }
  handleOpenModal = () => { this.setState({ isModalOpen: true }) }
  handleOpenModalToOpenTicket = () => { this.setState({ newTicketStatus: STATUS[1].codigo, isModalOpen: true }) }
  handleOpenModalToCloseTicket = () => { this.setState({ newTicketStatus: STATUS[2].codigo, isModalOpen: true }) }
  handleOpenModalToNeedDataTicket = () => { this.setState({ newTicketStatus: STATUS[4].codigo, isModalOpen: true }) }

  async handleChangeStatusTicket() {
    try {
      const payload = { id: this.props.idTicket, status: this.state.newTicketStatus }
      const response = await setStatusTicket(payload)
      if (response.status === 200) {
        this.setState({
          redirectReceived: true,
          isModalOpen: false,
          showSnackStatus: true,
          responseStatus: response.status,
          messageSnack: response.message,
          statusToMark: 0,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  renderRedirectReceived = () => {
    if (this.state.redirectReceived && !this.state.showSnackStatus) {
      return <Redirect to={this.state.previousRoute} />
    } else {
      return null
    }
  }


  handlerCloseSnackStatus = () => (this.setState({ showSnackStatus: false, redirectReceived: true }))

  renderSnackBarStatus = () => {
    return (
      <SnackBarStatus
        message={this.state.messageSnack}
        status={this.state.responseStatus}
        open={this.state.showSnackStatus}
        closeOn={this.handlerCloseSnackStatus}
      />
    )
  }


  render() {
    const { 
      subject, 
      code, 
      classes, 
      body, 
      fromEmail, 
      created, 
      handleShowReply, 
      mayUserReply, 
      mayUserOpen, 
      mayUserClose, 
      mayUserNeedData,
      attachments
    } = this.props
    const lengthAttacments = attachments ? attachments.length : null
    return (
      <div className={classes.root}>
        {this.renderRedirectReceived()}
        <Hidden mdUp>
          <Grid item xs={12}>
            <Grid container direction="row" alignItems="center" justify="center">
              <span style={{ width: '100vh' }}></span>
            </Grid>
          </Grid>
        </Hidden>
        <Paper elevation={1} className={classes.paper}>
          <Grid container alignItems="center">
            <Grid item lg={8} style={{ margin: 20 }}>
              <Typography variant="h5">Asunto: {subject}</Typography>
              <Typography variant="button" gutterBottom>
                Código {code}
              </Typography>
            </Grid>
            <Grid item lg={3} style={{ margin: 10 }} className={classes.headerControls}>
              <Typography style={{ margin: 4 }}>Tickets 1 de 10</Typography>
              <Icon
                style={{ margin: 2 }}
                color="primary"
                className={classNames(classes.icon, 'fas fa-angle-left')}
                fontSize="small"
              />
              <Icon
                style={{ margin: 2 }}
                color="primary"
                className={classNames(classes.icon, 'fas fa-angle-right')}
                fontSize="small"
              />
            </Grid>
          </Grid>
          <Grid container spacing={16}>
            <Grid item lg={2} style={{ marginLeft: 20, flex: 0 }}>
              <Icon
                style={{ margin: 2 }}
                color="primary"
                className={classNames(classes.icon, 'fas fa-user')}
                fontSize="large"
              />
            </Grid>
            <Grid item sm lg={8} container justify="space-between">
              <Grid item lg container direction="column" justify="space-between">
                <Grid item style={{ marginBottom: 2 }}>
                  <Typography variant="subtitle1">To: soporte@efilm.online</Typography>
                </Grid>
                <Grid item style={{ marginBottom: 2 }}>
                  <Typography variant="subtitle1">From: {fromEmail}</Typography>
                </Grid>
                <Grid item >
                  <Typography variant="subtitle1">Fecha: {Moment.utc(created).format('D MMMM YYYY - hh:mm:ss a')}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider style={{ marginTop: 10 }} />
          <Grid container direction="row">
            <Grid item xs={7} sm={8} lg={8}>
              <Typography style={{ color: '#BDBDBD', padding: 20 }} variant="subtitle1">Body</Typography>
            </Grid>
            <Grid item xs={3} lg={2} style={{ textAlign: 'right' }}>
              {mayUserNeedData ?
                <Tooltip title="Indicar que se necesita más datos" aria-label="Indicar que ticket necesita más datos">
                  <IconButton aria-label="Need more data" onClick={this.handleOpenModalToNeedDataTicket} >
                    <IconAssignmentLate style={{ color: '#FDC731' }} />
                  </IconButton>
                </Tooltip>
                : null}
              {mayUserOpen ?
                <Tooltip title="Marcar como abierto" aria-label="Marcar ticket como abierto">
                  <IconButton aria-label="Close" onClick={this.handleOpenModalToOpenTicket} >
                    <IconAssignmentTurnedIn style={{ color: 'green' }} />
                  </IconButton>
                </Tooltip>
                : null}
              {mayUserClose ?
                <Tooltip title="Marcar como cerrado" aria-label="Marcar ticket como cerrado">
                  <IconButton aria-label="Close" onClick={this.handleOpenModalToCloseTicket} >
                    <IconCancel color="error" />
                  </IconButton>
                </Tooltip>
                : null}
            </Grid>
            <Grid item xs={1} sm={1} lg={1}>
              {mayUserReply ?
                <Tooltip title="Responder" aria-label="Respondet">
                  <IconButton aria-label="Reply" onClick={handleShowReply} >
                    <IconReply />
                  </IconButton>
                </Tooltip>
                : null}
            </Grid>
            <Grid item lg={12} style={{ margin: 20, paddingRight: 20, minWidth: '90vh' }}>
              <Typography variant="body1" style={{ wordWrap: 'break-word' }}>{body}</Typography>
            </Grid>
          </Grid>
          <Divider style={{ marginTop: 10 }}/>
          <Grid container direction="row">
            <Grid item lg={12} style={{ paddingRight: 10, minWidth: '90vh' }}>
              {lengthAttacments ?
                <>
                  <Grid item xs={7} sm={8} lg={8}>
                    <Typography style={{ color: '#BDBDBD', padding: 20 }} variant="subtitle1">Adjuntos</Typography>
                  </Grid>
                  <Typography style={{ padding: 20 }} variant="subtitle1">{lengthAttacments} archivos adjuntos</Typography>
                  <Grid  item style={{ paddingRight: 10}}>
                    <Attachment
                      attachments={attachments}
                    />
                  </Grid>
                </>
              : null}
            </Grid>
          </Grid>
        </Paper>
        <Dialog
          open={this.state.isModalOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title"> Confirmación </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description"> {STATUS[this.state.newTicketStatus].mensajeConfirmacion} </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseModal} color="primary">No</Button>
            <Button onClick={this.handleChangeStatusTicket} color="primary">Sí</Button>
          </DialogActions>
        </Dialog>
        {this.renderSnackBarStatus()}
      </div>
    )
  }

}

export default withStyles(styles)(HeaderTicketMsg);