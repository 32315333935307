import React, { Component } from 'react';
import ListTickets from '../components/EListTickets/EListTickets';
import { getTickets } from '../api/tickets';
import EPagiantion from '../components/Common/EPagination/EPagination';
import ETicketsLoader from '../components/Common/ELoading/ETicketsLoader';

class CerradosTickets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageCount: 0,
      count: 0,
      closedTickets: [],
      isLoading: true
    }
    this.getReceivedTickets = this.getReceivedTickets.bind(this)
    this.handlerPageClick = this.handlerPageClick.bind(this)
  }

  componentDidMount() {
    this.getReceivedTickets()
  }

  async getReceivedTickets() {
    try {
      const filter = { status: '2' }
      const { count, results } = await getTickets(filter)
      const pageCount = Math.trunc((count / 20) + 1)
      if (results) {
        this.setState({
          closedTickets: results,
          pageCount,
          isLoading: false
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  //handlers
  async handlerPageClick(infoPage) {
    const { selected } = infoPage
    const page = selected + 1
    const params = { status: '2', page }
    const { results } = await getTickets(params)
    if (results) {
      this.setState({ newTickets: results })
    }
  }

  handlerClickTicket = (ticket) => {
    const { history } = this.props
    const { id } = ticket
    history.push({
      pathname: `/cerrado/${id}`,
      state: {
        detail: ticket,
        mayUserReply: false,
      }
    })
  }



  render() {
    return (
      <>
        {
          this.state.isLoading ?
            <ETicketsLoader /> :
            <ListTickets
              header={"Tickets cerrados"}
              tickets={this.state.closedTickets}
              onClickTicket={this.handlerClickTicket}
            />
        }
        {this.state.closedTickets.length > 0 ?
          <EPagiantion
            pageCount={this.state.pageCount}
            handlerPageClick={this.handlerPageClick}
          /> : null}
      </>
    )
  }
}

export default CerradosTickets;