import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete'
import ReplyIcon from '@material-ui/icons/Reply'
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import FaceIcon from '@material-ui/icons/Face';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import ChipInput from 'material-ui-chip-input'
import IconAttachment from '@material-ui/icons/AttachFile';
import Attachment from '../EAttachment/EAttachment'

const styles = theme => ({
    root: {
        width: '90%',
        margin: 20
    },
    paper: {
        height: '100%'
    },
    recipients: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 50,
        alignItems: 'center',
    },
    chips: {
        color: 'red'
    }
})


class Reply extends React.Component {
  constructor(props) {
    super(props);
    this.refInput = React.createRef()
    this.openInputFile = this.openInputFile.bind(this)
  }

  openInputFile(){
    this.refInput.current.click()
  }
  

  render(){
    const {
      classes,
      fromEmail,
      isSending,
      handleCloseReply,
      onChangeReply,
      onClickSend,
      recipients,
      onAddChip,
      onDeleteChip,
      onDeleteChipAttachment,
      onChangeFile,
      hasAttachment,
      labelAttachments
    } = this.props
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Grid container alignContent="center" alignItems="center">
                    <Grid item style={{ margin: 10, flex: 0 }} lg={2}>
                        <ReplyIcon color="primary" fontSize="default" />
                    </Grid>
                    <Grid item lg={8} style={{ margin: 10 }}>
                        <Chip
                            avatar={<Avatar><FaceIcon /></Avatar>}
                            variant="outlined"
                            label={fromEmail}
                        />
                    </Grid>
                    <Grid item lg={12} className={classes.recipients}>
                        <Typography style={{ color: '#9E9E9E' }} variant="body1">CC</Typography>
                        <ChipInput
                            style={{ marginLeft: 20 }}
                            disableUnderline
                            disabled={isSending}
                            value={recipients}
                            onAdd={(chip) => onAddChip(chip)}
                            onDelete={(chip, index) => onDeleteChip(chip, index)}
                        />
                    </Grid>
                </Grid>
                <Divider style={{ marginTop: 10 }} />
                <Grid spacing={8} wrap="wrap" direction="row" container alignItems="center">
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ margin: 20 }}>
                        <TextField
                            id="standard-multiline-static"
                            multiline
                            fullWidth
                            name="replica"
                            rows="5"
                            margin="normal"
                            disabled={isSending}
                            onChange={(event) => onChangeReply(event)}
                        />
                    </Grid>
                </Grid>
                {hasAttachment ? 
                    <Attachment 
                        attachments={labelAttachments} 
                        onDeleteChip={(item) => onDeleteChipAttachment(item)}
                    />
                : null}
                <Grid container alignContent="center" alignItems="center">
                    <Grid item style={{ margin: 20 }}>
                        <DeleteIcon onClick={handleCloseReply} color="primary" fontSize="default" style={{cursor: 'pointer'}}  />
                        <IconAttachment 
                            style={{marginLeft: 5, cursor: 'pointer'}} 
                            color="primary" 
                            fontSize="default"
                            onClick={this.openInputFile}
                        />
                        <input 
                            id="myInput"
                            ref={this.refInput}
                            style={{display: 'none'}}
                            onChange={onChangeFile}
                            type="file"
                        />
                    </Grid>
                    <Grid item style={{ margin: 20, marginLeft: 'auto' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={isSending}
                            onClick={() => onClickSend()}
                        >{isSending ? 'Enviando...' : 'Enviar'}
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    )
  }
}
export default withStyles(styles)(Reply);
