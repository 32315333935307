import React, {Component} from 'react';
import ListTickets from '../components/EListTickets/EListTickets';
import { getTickets } from '../api/tickets'

class RecibidosTickets extends Component{
    constructor(props) {
        super(props);
        this.state = {
            receivedTickets: []
        }
        this.getReceivedTickets = this.getReceivedTickets.bind(this)
    }

    componentDidMount() {
        this.getReceivedTickets()
    }

    async getReceivedTickets() {
        try {
            const filter = {status: '1'}
            const receivedTickets = await getTickets(filter)
            if (receivedTickets) {
                this.setState({receivedTickets})
            }
        } catch (error) {
            console.log(error)
        }
    }

    //handlers
    handlerClickTicket = (ticket) => {
        const { history } = this.props
        const { id } = ticket
        history.push({
            pathname: `/recibido/${id}`,
            state: {
                detail: ticket,
                mayUserReply: true,
                mayUserClose: true,
                mayUserNeedData: true,
            }
        })
    } 



    render() {
        return(
            <ListTickets
                header={"Tickets recibidos"}
                tickets={this.state.receivedTickets}
                onClickTicket={this.handlerClickTicket}
            />
        )
    }
}

export default RecibidosTickets;