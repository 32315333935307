import api from './api';

async function auth(query) {
    try {
        const {status, data} = await api.post('/api/v1/auth/', JSON.stringify(query))
        if (status === 200) {
            const response = {status, token: data.token}
            return response
        }
    } catch (error) {
        if (error.request.status === 0) {
            const status = error.request.status
            const response = {status, token: null}
            return response
        }
        const status = error.response.status
        const response = { status, token: null}
        return response
    }
}
export default auth;