import React, { Component } from 'react';
import errorLogo from '../../../error.svg'
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    container: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'center',
        height: 'calc(100vh)'

    },
    img: {
        width: '40%'

    }
})

class NotFound extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        
    }
    
    render () {
        const { classes } = this.props;
        return(
            <div className={classes.container}>
                <img className={classes.img} src={errorLogo} alt="error 404"/>
            </div>
        )
    }
}

export default withStyles(styles)(NotFound);