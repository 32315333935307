import React, { Component } from 'react';
import './App.css';
import MainRoute from './routes/routes';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import pink from '@material-ui/core/colors/pink';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Provider } from "react-redux";
import { persistor, store } from './store';
import { PersistGate } from 'redux-persist/integration/react'


const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: pink,
  },
  typography: {
    useNextVariants: true,
  }
})
class App extends Component {

  render() {
    console.log(process.env)
    return (
      <Provider store={store}>
        <PersistGate loaging={null} persistor={persistor}>
          <MuiThemeProvider theme={theme}>
            <CssBaseline/>
            <MainRoute/>
          </MuiThemeProvider>
        </PersistGate>
      </Provider>
      
    );
  }
}

export default App;
