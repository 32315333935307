import React from 'react';
import Moment from 'moment';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import IconReply from '@material-ui/icons/Reply';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Attachment from '../EAttachment/EAttachment'

const styles = theme => ({
  root: {
    marginLeft: 20,
    backgroundColor: theme.palette.background.paper,
    paddingBottom: 0,
    paddingTop: 0,
  },
  rootListReplies: {
    width: '90%',
    margin: 20,
  },
  listNav: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  paper: {
    height: '100%',
  },
  listRepliesInside: {
    borderLeft: '5px solid cyan',
  },
  listItemRepliesInside: {
    marginTop: 0,
    marginBottom: 0,
  },
  listItemSecondaryAction: {
    position: 'absolute',
    top: 15,
  },
  iconReplyFromList: {
    padding: 2,
    marginTop: 2,
    marginLeft: 12,
  },
});

class ListReplies extends React.Component {
  constructor (props) {
    super (props);
    this.listRepliesInside = this.listRepliesInside.bind (this);
  }

  listRepliesInside = (classes, replies, onClickTicket) => {
    return (
      <div className={classes.root}>
        <List component="nav" className={classes.listNav}>
          {replies.length > 0
            ? replies.map ((value, key) => (
                <div className={classes.listRepliesInside} key={key}>
                  <ListItem
                    key={value.id}
                    role={undefined}
                    dense
                    button
                    onClick={null}
                  >
                    <div>
                      <ListItemText
                        primary={value.email_user}
                        secondary={value.body}
                      />
                      <ListItemSecondaryAction>
                        <Typography variant="caption">
                          {Moment.utc (value.created).format (
                            'D MMMM YYYY - hh:mm:ss a'
                          )}
                          {this.props.mayUserReply
                            ? <IconButton aria-label="Reply">
                                <IconReply
                                  onClick={this.props.handleShowReply}
                                />
                              </IconButton>
                            : null}
                        </Typography>
                      </ListItemSecondaryAction>
                    </div>
                  </ListItem>
                  <Divider />
                  {this.listRepliesInside (classes, value.replies, null)}
                </div>
              ))
            : null}

        </List>
      </div>
    );
  };

  render () {
    const {classes, tickets, assignedUser} = this.props;
    return tickets.length > 0
      ? <div className={classes.rootListReplies}>
          <Paper elevation={1} className={classes.paper}>
            <Grid container alignItems="center">
              <Grid item xs={12} style={{margin: 20, width: '100%'}}>
                <List component="nav" className={classes.listNav}>
                  {tickets.map ((value, key) => {
                    const email = value.admin_answer
                      ? 'YO: ' + assignedUser.email
                      : value.email_user;
                    let body = value.body.indexOf (
                      '<div class="gmail_quote">'
                    ) > -1
                      ? value.body.split ('<div class="gmail_quote">')[0]
                      : value.body;
                    return (
                      <div key={key}>
                        <ListItem
                          key={value.id}
                          role={undefined}
                          dense
                          button
                          onClick={null}
                        >
                          <div>
                            <ListItemText
                              primary={email}
                              secondary={
                                <React.Fragment>
                                  <Typography variant="caption">
                                    <Hidden mdUp>
                                      {Moment.utc (value.created).format (
                                        'D MMMM YYYY - hh:mm:ss a'
                                      )}
                                    </Hidden>
                                  </Typography><br />
                                  <Grid item lg={12} style={{maxWidth: '90vh'}}>
                                    <Typography
                                      variant="caption"
                                      style={{wordWrap: 'break-word'}}
                                      dangerouslySetInnerHTML={{__html: body}}
                                    />
                                  </Grid>
                                </React.Fragment>
                              }
                            />
                            <ListItemSecondaryAction
                              className={classes.listItemSecondaryAction}
                            >
                              <Typography variant="caption">
                                <Hidden smDown>
                                  {Moment.utc (value.created).format (
                                    'D MMMM YYYY - hh:mm:ss a'
                                  )}
                                </Hidden>
                              </Typography>
                            </ListItemSecondaryAction>
                          </div>
                        </ListItem>
                        <Divider />
                        {value.attachments.length > 0 ? 
                          <>
                            <Grid item xs={7} sm={8} lg={8}>
                              <Typography style={{ color: '#BDBDBD', padding: 20 }} variant="subtitle1">Adjuntos</Typography>
                            </Grid>
                              <Typography style={{ padding: 20 }} variant="subtitle1">{value.attachments.length} archivos adjuntos</Typography>
                            <Grid  item style={{ paddingRight: 10}}>
                              <Attachment
                                attachments={value.attachments}
                              />
                            </Grid>
                          </>
                        : null}
                      </div>
                    );
                  })}
                </List>
              </Grid>
            </Grid>
          </Paper>
        </div>
      : null;
  }
}

export default withStyles (styles) (ListReplies);
