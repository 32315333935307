import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import CommentIcon from '@material-ui/icons/Comment';
import CloseIcon from "@material-ui/icons/Close";
import ReplyIcon from "@material-ui/icons/Reply";
import Typography from '@material-ui/core/Typography';
import {getTickets} from '../../api/tickets';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: 20,
  },
  title:{
    padding: 20
  }
});

class CheckboxList extends React.Component {
  constructor(props) {
    super(props);
    this.getInboxTickets = this.getInboxTickets.bind(this)
  }
  
  state = {
    checked: [0],
    tickets: []
  };

  componentDidMount() {
    this.getInboxTickets()
  }


  handleToggle = value => () => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  };

  async getInboxTickets() {
    try {
      const tickets = await getTickets()
      if (tickets) {
        console.log(tickets)
        this.setState({tickets: tickets})
      }
    } catch (error) {
      console.log(error)
      
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="title" gutterBottom className={classes.title}>
          Bandeja de entrada
        </Typography>
        <List>
          {this.state.tickets.map((value) => (
            <ListItem key={value.id} role={undefined} dense button onClick={this.handleToggle(value)}>
              <ListItemText primary={value.subject} secondary={value.status} />
              <ListItemSecondaryAction>
                <IconButton aria-label="Reply">
                  <ReplyIcon />
                </IconButton>
                <IconButton aria-label="Comments">
                  <CommentIcon />
                </IconButton>
                <IconButton aria-label="Closed">
                  <CloseIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
    );
  }
}

CheckboxList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CheckboxList);
