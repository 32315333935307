import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ReactLoading from 'react-loading';
import Typography from '@material-ui/core/Typography';
const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'row wrap',
    height: "calc(60vh)",
    flex: 1
  },
  loader: {
    margin: '0 auto',
    width: 110 + 'px !important',
    height: 20,
    fill: '#0097EF !important'
  },
  progress: {
    width: '100%',
    marginLeft: 12

  }
})


const Loading = ({classes}) => {
  return (
    <div className={classes.root}>
      <div className={classes.loading}>
          <ReactLoading type="bubbles" className={classes.loader} />
          <br/>
          <Typography style={{color: '#9E9E9E', textAlign: 'center'}} variant="caption">Cargando</Typography>
      </div>
    </div>
  )
}

export default withStyles(styles)(Loading);